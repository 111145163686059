import axios from "axios";

export const sendMail = (frmData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        "https://d20yfzezz48v4o.cloudfront.net/eform-india/sendEnquiryFormEmailIndia",
        frmData
      );
      resolve(res);
      console.log(res);
    } catch (error) {
      reject(error);
    }
  });
};
