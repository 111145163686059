"use client";
import {
  Typography,
  Stack,
  TextField,
  Grid,
  Chip,
  Autocomplete,
  Button,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { sendMail } from "../api";
import MagicButton from "./MagicBtn";

const InputTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& label": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff",
  },
  "& .MuiFilledInput-root": {
    color: "#fff",
    backgroundColor: "rgba(255, 255, 255, 0.06)",
    borderColor: "rgba(255, 255, 255, 1)",
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 1)",
    },
    "&:hover fieldset": {
      borderColor: "#FF512F",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF512F",
    },
  },
  "& .MuiFilledInput-root:after:hover": {
    borderColor: "#FF512F",
  },
  "& .MuiFilledInput-root:before": {
    color: "#fff",
    borderColor: "rgba(255, 255, 255, .2)",
    "& fieldset": {
      borderColor: "#FF512F",
    },
    "&:hover fieldset": {
      borderColor: "#fff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#fff",
    },
  },
  "& .MuiFilledInput-root:after": {
    color: "#fff",
    backgroundColor: "rgba(255, 255, 255, 0.06)",
    borderColor: "#FF512F",
    "& fieldset": {
      borderColor: "#FF512F",
    },
    "&:hover fieldset": {
      borderColor: "#fff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#fff",
    },
  },
});

function HeroEnquiryForm({ heroPage, popup }) {
  const [service, setService] = useState([]);
  const industries = [
    "Aerospace & Defence",
    "Automotive",
    "Energy and Utilities",
    "Farming and Agriculture",
    "Finance",
    "Healthcare",
    "Manufacturing Industries",
    "Retail",
    "Technology",
    "Travel",
    "Others",
  ];
  const services = [
    "Bespoke Software Development",
    "Cloud Service",
    "E-Commerce",
    "Data Analytics and Visualization",
    "DevOps",
    "Digital Marketing",
    "Software Development",
    "UI/UX Design",
    "Web 3.0 Apps",
    "Website Design",
    "Web & Mobile Application",
  ];
  const [estimatedBudget, setEstimatedBudget] = useState("");
  const [industry, setIndustry] = useState("");
  const [isSending, setIsSending] = useState(false);
  const autoC = useRef(null);
  const ele = autoC.current?.getElementsByClassName(
    "MuiAutocomplete-clearIndicator"
  )[0];
  const { enqueueSnackbar } = useSnackbar();

  const emailRegex = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
  const mobileNumberRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const initialValues = {
    name: "",
    mobileNumber: "",
    email: "",
    note: "",
    companyName: "",
  };
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    note: Yup.string().required("Required"),
    email: Yup.string()
      .matches(emailRegex, "Enter valid email")
      .required("Required"),
    mobileNumber: Yup.string()
      .matches(mobileNumberRegExp, "mobile number is not valid")
      .min(10, "too short")
      .max(10, "too long")
      .required("Required"),
  });

  const handleSubmit = async (values, props) => {
    values.service =
      service.length > 0 ? service?.join(", ") : "";
    values.industry = industry;
    values.estimatedBudget = estimatedBudget;
    console.log(values);
    try {
      setIsSending(true);
      const res = await sendMail(values)
      if (res.status === 201) {
        setIsSending(false);
        enqueueSnackbar("Form Submitted Successfully.", { variant: "success" });
        setIndustry("")
        setEstimatedBudget("")
        props.resetForm();
        setService([]);
        ele.click();
      } else {
        setIsSending(false);
        console.log(res, "res");
      }
    } catch (err) {
      setIsSending(false);
      console.log(err, "err");
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };
  return (
    
    <Stack
    borderRadius="1rem"
    p={'2px'}
    sx={{
    
      width: ["100%", "120%", "120%", "95%", "85%"],
      transform: ["scale(1)", "scale(.8)", "scale(.8)", "scale(1)"],
      display: ["flex"],
      height: "max-content",
      zIndex: 21,
        background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
    }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{
          width: ["100%", ],
          borderRadius: "1rem",
          backgroundColor: '#080f25',
          display: ["flex"],
          height: "max-content",
          justifyContent: ["flex-start", "space-between"],
          flexDirection: "column",
          p: ["1rem", "2.4rem", "2.3rem", "3.2rem", "4.1rem"],
          py: ["1.5rem", "2rem", "3.3rem", "4.2rem", "4.1rem"],
          gap: [2, 1],
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          width={{ xs: "100%", sm: "48%", md: "48%" }}
          sx={{
            gap: [1],
            height: ["auto"],
          }}
        >
          <Typography
            sx={{
              fontSize: ["1.6rem", "2.5rem", "3rem", "3rem", "3.2rem"],
              background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
              backgroundClip: "text",
              textFillColor: "transparent",
              width: "fit-content",
              fontWeight: "600",
            }}
          >
            Get in touch with us!
          </Typography>
          <Typography
            sx={{
              width: "100%",
              fontSize: ["1rem", "1rem", "1.2rem", "1.3rem"],
              color: "#fff",
            }}
          >
            Whether you are in education, finance, transportation, retail or
            health care, we have a solution for you. We develop both large and
            small projects and our solutions are always tailored to your needs -
            both in terms of budget and scope.
          </Typography>
        </Stack>
        <Stack
          sx={{
            width: ["1px"],
            minHeight: ["100%"],
            bgcolor: "#FF512F",
          }}
        />
        <Stack
          direction="column"
          justifyContent="space-between"
          width={{ xs: "100%", sm: "46%" }}
          sx={{
            height: ["auto"],
            transform: [
              "scale(1)",
              "scale(1)",
              "scale(1)",
              "scale(.9)",
              "scale(.9)",
            ],
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form>
                <Grid spacing={2} container>
                  <Grid item xs={12}>
                    <Field
                      as={InputTextField}
                      autoComplete="name"
                      name="name"
                      fullWidth
                      required
                      size="small"
                      id="name"
                      variant="filled"
                      label="Name"
                      helperText={<ErrorMessage name="name" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={InputTextField}
                      autoComplete="companyName"
                      name="companyName"
                      fullWidth
                      required
                      size="small"
                      id="companyName"
                      variant="filled"
                      label="Company Name"
                      helperText={<ErrorMessage name="companyName" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={InputTextField}
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      variant="filled"
                      size="small"
                      autoComplete="email"
                      helperText={<ErrorMessage name="email" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={InputTextField}
                      autoComplete="mobileNumber"
                      name="mobileNumber"
                      variant="filled"
                      fullWidth
                      size="small"
                      id="mobileNumber"
                      required
                      label="Mobile No"
                      helperText={<ErrorMessage name="mobileNumber" />}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      label={"s"}
                      ref={autoC}
                      options={industries}
                      style={{ color: "#181818" }}
                      placeholder={"dd"}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        setIndustry(value);
                      }}
                      renderInput={(params) => (
                        <InputTextField
                          {...params}
                          label="Industries"
                          fullWidth
                          variant="filled"
                          size="small"
                          sx={{
                            input: {
                              "&::placeholder": {
                                opacity: 0.8,
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      label={"s"}
                      ref={autoC}
                      multiple
                      options={services}
                      style={{ color: "#fff" }}
                      placeholder={"dd"}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        setService(value);
                        console.log(service);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="filled"
                            label={option}
                            sx={{
                              color:'white'
                            }}
                            size="small"
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <InputTextField
                          {...params}
                          label="Choose the service"
                          fullWidth
                          variant="filled"
                          size="small"
                          sx={{
                            input: {
                              "&::placeholder": {
                                opacity: 0.8,
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      className="note-input"
                      as={InputTextField}
                      variant="filled"
                      fullWidth
                      id="note"
                      label="Special Note"
                      name="note"
                      size="small"
                      multiline
                      rows={3}
                      helperText={<ErrorMessage name="note" />}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <MagicButton>
                      {isSending ? "Sending..." : "Enquire Now"}</MagicButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default HeroEnquiryForm;
